import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components';
import HeaderNav from '../components/Header/HeaderNav'

const NotFoundContainer = styled.section`
    width: 100vw;
    height: 100vh;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const MainTitle = styled.h1`
    color: white;
    font-size: 14vw;

    @media screen and (min-width: 820px){
        font-size: 100px;
    }
`

const NexistePas = styled.h2`
    color: white;
    font-size: 6vw;
    margin-bottom: 120px;

    @media screen and (min-width: 820px){
        font-size: 40px;
    }
`

const Revenir = styled.p`
    a {
        color: #777;
        text-decoration: none;
    }
`

const NotFound = () => {
    return (
        <NotFoundContainer>
            <HeaderNav title="404" />

            <MainTitle>ERREUR 404</MainTitle>
            <NexistePas>La page demandée n'existe pas</NexistePas>
            <Revenir><Link to="/" style={{color:"#777"}}>Revenir à l'accueil</Link></Revenir>
        </NotFoundContainer>
    )
}

export default NotFound